<template>
  <div>
    <v-container>
      <v-row v-if="planDoc">
        <v-col cols="12" md="8" sm="8">
          <div style="font-size: 50px;font-weight: bold;color: lightblue">{{ $t('agent').toUpperCase() }}</div>
          <h1>{{ $t('bayonApp') }}</h1>

          <br>
          <div style="text-align: center;max-width: 800px">
            <h2 style="color: #6c2c73">{{ planDoc.name }}</h2>
            <h4><p>{{ planDoc.advertiseNote }}</p></h4>

            <v-list-item>
              <v-list-item-icon>
                <v-icon color="green">mdi-checkbox-marked-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title style="text-align: left">{{ $t('numProperty') }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>{{ planDoc.numProperty }}</v-list-item-action>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon>
                <v-icon color="green">mdi-checkbox-marked-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title style="text-align: left">{{ $t('numRenew') }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>{{ planDoc.numRenew }}</v-list-item-action>
            </v-list-item>


            <v-list-item v-if="planDoc.name==='Agency' || planDoc.name==='Developer'">
              <v-list-item-icon>
                <v-icon color="green">mdi-checkbox-marked-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title style="text-align: left">{{ $t('numAgent') }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>{{ planDoc.numAgent }}</v-list-item-action>
            </v-list-item>

          </div>

        </v-col>
        <v-col cols="12" md="4" sm="4">
          <v-card
              class="mx-auto"
              max-width="344"
          >
            <v-overlay :value="isLoadingImg">
              <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card-text>
              <p class="text-h4 text--primary">
                {{ $t('yourPlan') }} <span style="float: right;color:#6c2c73">{{ planDoc.name }}</span>
              </p>

              <v-divider style="margin-top: 20px;margin-bottom: 20px"></v-divider>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                    v-model="dataObj.name"
                    :rules="requireInput"
                    :label="$t('name')"
                    persistent-hint
                    required
                    :dense="dense"

                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                    v-model="dataObj.phoneNumber"
                    :rules="requireInput"
                    :label="$t('phoneNumber')"
                    persistent-hint
                    required
                    :dense="dense"

                ></v-text-field>
              </v-col>

              <v-col cols="12" md="12" sm="12">
                <v-text-field :label="$t('email')" required
                              v-model="dataObj.email"
                              :rules="emailRulesOptional"
                              :dense="dense"
                              placeholder="admin@gmail.com"

                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="d-flex">
                <v-autocomplete
                    v-model="dataObj.countryId"
                    :items="countryList"
                    outlined
                    :rules="requireInput"
                    :dense="dense"
                    color="blue-grey lighten-2"
                    :label="$t('country')"
                    item-text="name"
                    item-value="number"
                >
                  <template v-slot:selection="data">
                    <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                    >
                      <v-avatar left>
                        <v-img :src="data.item.flag" :alt="data.item.name"></v-img>
                      </v-avatar>
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <v-list-item-avatar>
                      <img :src="data.item.flag" :alt="data.item.name">
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.name"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="12" class="d-flex">
                <v-autocomplete v-model="dataObj.locationId"
                                :items="locationOptionList"
                                outlined
                                :dense="dense"
                                clearable
                                item-text="label"
                                item-value="value"
                                :label="$t('city')"
                                required
                                :rules="requireInput"
                                search-input
                >
                  <template v-slot:selection="{ item }">
                    {{ item.label }}
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="12" sm="12" md="12">
                <v-autocomplete v-model="dataObj.agentType"
                                :items="agentTypeOption"
                                :dense="dense"
                                item-text="label"
                                item-value="value"
                                :label="$t('agentType')"
                                search-input
                                required
                >
                  <template v-slot:selection="{item}">
                    {{ $t(item.value) }}
                  </template>
                  <template v-slot:item="{item}">
                    {{ $t(item.value) }}
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                    v-model="dataObj.address"
                    :rules="requireInput"
                    :label="$t('address')"
                    persistent-hint
                    required
                    :dense="dense"

                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <img v-if="!dataObj.photo" src="@/assets/images/avatar.png" style="height: 130px; width: auto;"
                     class="mb-4 mt-2"
                     alt="logo" @click="$refs.fileInput.click()"/>
                <img v-if="dataObj.photo" :src="dataObj.photo || '@/assets/images/avatar.png'"
                     style="height: 130px; width: auto;"
                     class="mb-4 mt-2"
                     alt="logo" @click="$refs.fileInput.click()"/>
                <input style="display: none !important;" type="file" @change="onFileSelected"
                       ref="fileInput"/>
              </v-col>
              <next-button @nextForm="goToPayment('add-payment-noparam',{planDoc:planDoc,userId:userId,agentDoc:dataObj})"
                           :valid="valid && !isLoadingImg"></next-button>
            </v-card-text>

          </v-card>

        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {provider} from "@/service/provider";
import axios from "axios";
import "../firebase/config";

//import NextStep from "@/components/nextStep"
//import CloseButton from "@/components/closeButton"
import {Constants} from "@/libs/constant";
import moment from "moment";
import MainMixin from "@/mixins/mainMixin";
import {countyMobileList} from "@/libs/countryMobileWithFlag";

const Compress = require('compress.js')
import firebase from 'firebase/app'
import NextButton from "@/components/nextButton";

export default {
  name: "Payment",
  components: {NextButton},
  //components: {NextStep, CloseButton},
  mixins: [MainMixin],

  data() {
    return {
      valid: true,
      cards: "",
      dense: true,
      dialog: false,
      isLoadingImg: false,
      planDoc: {},
      dialogPersonalInfo: false,
      type: "",
      userId: "",
      countryList: countyMobileList,
      dataObj: {
        name: "",
        address: "",
        email: "",
        countryId: "",
        locationId: "",
        phoneNumber: "",
        startDate: moment().format("YYYY-MM-DD"),
        dob: moment().add(-18, "years").format("YYYY-MM-DD"),
        status: true,
        description: "",
        agentType: "fullTime",
        nationId: "",
        type: "Identity Card",
        salary: "",
        upLineId: "",
        sponsorId: "",
        branchId: "",
        photo: "",
        facebook: "",
        telegram: ""
      },
      nameRules: [
        v => !!v || 'Plan Name is required',
      ],
      requireRules: [
        v => v === 0 || !!v || 'is required',
      ],
      codeRules: [
        v => !!v || 'Property Code is required',
      ],
      requireInput: [
        v => !!v || 'Please Input Data',
      ], selectRules: [
        v => !!v || 'Please Choose one',
      ],
      emailRulesOptional: [
        v => v === '' || /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      changeValue: true,
      abaPayWayApiUrl: Constants.abaPayWayApiUrl,
      selectedFile: "",
      fileName: ""
    }
  },
  methods: {
    goToPayment(link, params) {
      let vm = this;
      vm.dialog = false;
      if (vm.$router.history.current.path !== link) {
        this.$router.push({
          name: link,
          params: params,
        });
      }
    },
    onFileSelected(e) {
      let vm = this;
      vm.fileName = e.target.files[0].name;
      let tmpFile = [...e.target.files];
      const compress = new Compress();
      compress.compress(tmpFile, {
        size: 4, // the max size in MB, defaults to 2MB
        quality: 0.6, // the quality of the image, max is 1,
        maxWidth: 1920, // the max width of the output image, defaults to 1920px
        maxHeight: 1920, // the max height of the output image, defaults to 1920px
        resize: true // defaults to true, set false if you do not want to resize the image width and height
      }).then((data) => {
        data.forEach((obj) => {
          let img1 = obj;
          let base64str = img1.data;
          let imgExt = img1.ext;
          this.selectedFile = Compress.convertBase64ToFile(base64str, imgExt), obj.alt.split(".")[0];
        })
      })
      setTimeout(function () {
        vm.onUpload();
      }, 500);
    },
    onUpload() {
      let vm = this;
      vm.isLoadingImg = true;
      const storageRef = firebase.storage().ref("web/agent_images/agent" + moment().format("YYYYMMDDHHmmss") + this.fileName).put(this.selectedFile);
      storageRef.on(`state_changed`, snapshot => {
            this.uploadValue = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          }, error => {
            console.log(error.message)
          },
          () => {
            this.uploadValue = 100;
            storageRef.snapshot.ref.getDownloadURL().then((url) => {
              vm.dataObj.photo = url || "";
              vm.isLoadingImg = false;
            });
          }
      );
    },

    getPlanById(id) {
      let vm = this;
      let url = provider.baseURL + `/plan/byId?id=${id}`;
      return new Promise((resolve, reject) => {
        axios.get(url, {headers: {token: provider.token}}).then(
            res => {
              if (res.data.code === 201) {
                vm.planDoc = res.data.data;
              }
            },
            error => {
              reject(error);
            }
        );
      });
    }

  },
  watch: {
    "dataObj.countryId"(val) {
      this.queryLocation(val);
    }
  },
  created() {
    let vm = this;
    vm.planDoc = vm.$route.params.planDoc;
    vm.userId = vm.$route.params.userId;

    if (!vm.$route.params.planId) {
      if (!(vm.$route.params && vm.$route.params.planDoc && vm.userId)) {
        vm.$router.push({name: "web-home"});
      }
    } else {
      vm.getPlanById(vm.$route.params.planId);
    }
    vm.fetchAgentTypeOption();

  }
}

</script>